'use strict'

angular.module('cb.saleorder-service', [])
	.factory('SaleOrderService', ["ApiService", function (ApiService) {

		var SaleOrderService = {

			quickSearch: function (data) {
				try {
					var url = '/webapi/api/v1/search/quickSearch/' + data;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			newOrderLine: function (data) {
				try {
					var url = '/webapi/api/v1/saleOrder/orderline/new';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			newOrderLineFromQuote: function (data) {
				try {
					var url = '/webapi/api/v1/saleOrder/orderlinefromquote/new';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			getOrderLines: function (Id) {
				try {
					var url = '/webapi/api/v1/saleOrder/orderline/view/' + Id;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			updateOrderLine: function (data) {
				try {
					var url = '/webapi/api/v1/saleorder/orderline/update';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			deleteOrderLine: function (data) {
				try {
					var url = '/webapi/api/v1/saleorder/orderline/delete';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			deleteOrder: function (data) {
				try {
					var url = '/webapi/api/v1/saleorder/orderline/cancelorder';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			updateOrder: function (data) {
				try {
					var url = '/webapi/api/v1/saleOrder/updateOrder';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			getPayment: function (id) {
				try {
					var url = '/webapi/api/v1/payment/' + id;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			createPayment: function (data) {
				try {
					var url = '/webapi/api/v1/payment/create';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			updatePayment: function (data) {
				try {
					var url = '/webapi/api/v1/payment/update';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			removePayment: function (id) {
				try {
					var url = '/webapi/api/v1/payment/' + id + '/remove';
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			getSurveyMaintenance: function (id) {
				try {
					var url = '/webapi/api/v1/surveyMaintenance/' + id;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			getCurrentFitter: function (id) {
				try {
					var url = '/webapi/api/v1/surveyMaintenance/getCurrentFitter/' + id;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			getCurrentFitterByStaffId: function (id) {
				try {
					var url = '/webapi/api/v1/surveyMaintenance/getCurrentFitterByStaffId/' + id;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			createSurveyMaintenance: function (data) {
				try {
					var url = '/webapi/api/v1/surveyMaintenance/create';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			updateSurveyMaintenance: function (data) {
				try {
					var url = '/webapi/api/v1/surveyMaintenance/update';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			removeSurveyMaintenance: function (id) {
				try {
					var data = { Id: id };
					var url = '/webapi/api/v1/surveyMaintenance/delete';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			getRemake: function (id) {
				try {
					var url = '/webapi/api/v1/remake/' + id;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			createRemake: function (data) {
				try {
					var url = '/webapi/api/v1/remake/create';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			updateRemake: function (data) {
				try {
					var url = '/webapi/api/v1/remake/update';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			removeRemake: function (id) {
				try {
					var data = { Id: id };
					var url = '/webapi/api/v1/remake/delete';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			removeFile: function (id) {
				try {
					var url = '/webapi/api/v1/file/' + id + '/Delete';
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			getUrgentOrders: function (data) {
				try {
					var url = '/webapi/api/v1/saleorder/getUrgentOrders';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			getSaleOrdersForStaff: function (data) {
				try {
					var url = '/webapi/api/v1/saleorder/getSaleOrdersForStaff';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			updateBlindsCount: function (data) {
				try {
					var url = '/webapi/api/v1/saleorder/orderline/update/figures';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			getSurveyHistory: function (data) {
				try {
					var url = '/webapi/api/v1/surveyMaintenance/getSurveyHistory/' + data;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			
			iPadCreateSurvey: function (data) {
				try {
					var url = '/webapi/api/v1/surveyMaintenance/iPadCreateSurvey';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			DeleteSurvey: function (data) {
				try {
					var url = '/webapi/api/v1/surveyMaintenance/iPadDeleteSurvey';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			SelectSurvey: function (data) {
				try {
					var url = '/webapi/api/v1/surveyMaintenance/iPadSelectSurvey';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			saveGuaranteeLevel: function (data) {
				try {
					var url = '/webapi/api/v1/saleorder/checkGuaranteeForOrder';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			FetchPaymentDetails: function (data) {
				try {
					var url = '/webapi/api/v1/Payment/FetchPaymentDetails';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			}
		};

		return SaleOrderService;
	}]);
